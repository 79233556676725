<template>
  <div id="app">
    <search-progress-bar
         v-if="progressBar.active"
         :data="progressBar.data"
         @close-notification-search-progress="handleProgressSearch"
     />
    <the-header/>
    <SideBar/>
    <div style="margin-top: 50px" class="container is-fluid">
      <router-view :key="$route.fullPath"/>
    </div>
  </div>
</template>
<script>
import {openSocketConnection} from "./services/socket.js";
import SideBar from "@/components/SideBar";
import SearchProgressBar from "./components/SearchProgressBar";
import TheHeader from "./components/TheHeader";

export default {
  name: 'App',
  components: {
    TheHeader,
    SearchProgressBar,
    SideBar,
  },
  data(){
    return {
      progressBar: {
        active : false,
        data: {}
      },
    }
  },
  created() {

    this.progressBar.data = JSON.parse(localStorage.getItem('searchProgress'));

    if (this.progressBar.data){
      this.progressBar.active = true
    }

    openSocketConnection().then(res => {
      console.log("succès à la connexion socket", res)
    }).catch(e => {
      console.error(e)
    });

    this.searchProgressEventListener()
    this.errorEventListener()
  },
  methods: {
    handleProgressSearch(active){

      if (!active){
        this.progressBar.data = {}
      }

      this.progressBar.active = active
    },
    errorEventListener(){
      window.addEventListener("getErrorEvent", (event) => {
        if (event.detail.content) {
          console.error(event.detail.content);
          if(event.detail.type === 'wrongSubscriptionPlan'){
            this.$vs.notification({
              sticky: true,
              color:'danger',
              position : 'top-center',
              title: 'Error',
              text: event.detail.content
            });

            this.notificationError(event.detail.content.toString())
          }else{
            this.notificationError(event.detail.content.toString())
          }

        }
      });
    },
    searchProgressEventListener(){
      window.addEventListener("searchProgressEvent", () => {
        this.progressBar.data = JSON.parse(localStorage.getItem('searchProgress'))
        this.progressBar.active = this.progressBar.data.maxProgress !== 0
      });
    }
  },
}
</script>
