//let connection;
const {getCookie} = require('../utils/cookie/cookie');
const io = require('socket.io-client');
export async function openSocketConnection(){
    console.log("opening socket connection..");
    return new Promise(resolve => {
        let cookie = getCookie("token");

        console.log(cookie);
        let  socket = io(process.env.VUE_APP_API,{transport : ['websocket'] ,  query : { token :cookie , origin : "app"} });
        socket.on('disconnect', function() {
           // socket.emit('disconnect')
        });

        socket.on('connect', () => {
            console.log("Connected with socket to the server")
        });

        socket.on('message', (message) => {
            console.log("Getting message from server",message);

            if (message) {
                let event;
                switch (message.type) {
                    case 'goFetchResults':
                        event = new CustomEvent('getResultsEvent', {
                            detail: {
                                result: message.result,
                                searchId: message.searchId,
                                enrichment: message.enrichment
                            }
                        });
                        window.dispatchEvent(event);
                        break;
                    case 'goFetchFavoriteDomains':
                        event = new CustomEvent('goFetchFavoriteDomains');
                        window.dispatchEvent(event);
                        break;
                    case 'error':
                        event = new CustomEvent('getErrorEvent', {
                            detail: {
                                content: message.errorContent,
                                details: message.errorDetails,
                                type: message.errorType
                            }
                        });
                        window.dispatchEvent(event);
                        break;
                    default:
                        console.log(`Sorry, we are out of ${message.type}.`);
                }

            }

        });
        resolve(true)
    })
}



