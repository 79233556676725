export default {
    methods: {
        notificationError(text) {

            this.$vs.notification({
                classNotification: 'danger',
                duration: 7000,
                border: 'danger',
                icon: `<i class='bx bxs-bug'></i>`,
                position: 'top-right',
                title: 'Error',
                text: text
            })

        },
        notificationSuccess(text) {

            this.$vs.notification({
                classNotification: 'success',
                duration: 7000,
                border: 'success',
                icon: `<i class='bx bx-bell'></i>`,
                position: 'top-right',
                title: 'Success',
                text: text
            })

        }
    }
}