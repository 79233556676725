<template>
  <div class="header">
    <vs-navbar center-collapsed fixed v-model="active">
      <template #left>
        <a @click="$router.push('/')"><img src="../../public/logo.png" alt="logo" width="140px"></a>
      </template>
      <template #right>
        <language-switcher/>
      </template>
    </vs-navbar>
  </div>
</template>

<script>
import LanguageSwitcher from "./LanguageSwitcher";

export default {
  name: "TheHeader",
  components: {LanguageSwitcher}
}
</script>

<style scoped lang="scss">

.header .vs-navbar-content{
  padding: 4px 0px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
}

</style>