  <template>
    <div>
      <div class="notification-search-progress notification-parent vs-notification-parent vs-notification-parent--top-right">
        <div
            class="notification vs-notification vs-notification--color vs-notification--sticky vs-notification--dark notification-enter-to"
            style="padding:10px 20px;">
          <div class="vs-notification__content">
            <div class="vs-notification__content">
              <header class="vs-notification__content__header center">
                <h4 v-if="data.type === 'enrichment'">{{$t('search.audit_competitor')}}</h4>
                <h4 v-else>Search</h4>
              </header>
              <div class="notification-content">
                <div class="columns search-progress">
                  <div class="column">
                    <b-progress
                        type="is-success"
                        :value=this.percentProgress
                        show-value
                        format="percent"
                    >
                    </b-progress>
                  </div>
                  <div class="column is-narrow">
                    <vs-button
                        v-if="!isOver"
                        icon
                        relief
                        danger
                        animation-type="rotate"
                        @click="stopCurrentSearch"
                    >
                      <i class='bx bx-stop'/>
                    </vs-button>
                  </div>
                </div>
                <p>{{ $t('search.progress.count_keyword', {find: valueProgress, total: maxProgress}) }}</p>
                <p v-if="!isOver">{{$t('search.progress.time', { time : this.estimatedTime })}}</p>
              </div>
            </div>
          </div>
          <button v-if="isOver" class="vs-notification__close" @click="close()">
            <i class="vs-icon-close vs-icon-hover-less"></i>
          </button>
        </div>
      </div>
    </div>

  </template>

  <script>
  import {stopSearch} from "@/services/extensionMessaging";
  import EventBus from "../services/event-bus";

  export default {
    name: "SearchProgressBar",
    props: {
      data : {
        type: Object,
      }
    },
    data() {
      return {
        valueProgress: 0,
        maxProgress: 100,
        estimatedTime: 0,
        isOver: false,
      }
    },
    computed: {
      percentProgress() {
        return Math.round(this.valueProgress * 100 / this.maxProgress)
      },
    },
    watch: {
      data(value) {
        this.valueProgress = value.valueProgress
        this.maxProgress = value.maxProgress
        this.estimatedTime = this.toHHMMSS(value.estimatedTime)

        // End of search
        if (this.isSearchEnd()) {
          this.isOver = true
          window.localStorage.removeItem('searchProgress')
          window.removeEventListener('searchProgressEvent', () => {
            console.log('Remove event')
          })

          if (this.$route.params.reload == "true") {
            this.$router.replace({params: {reload: false}})
          }
        } else {
          this.isOver = false
        }
      }
    },
    mounted() {
      this.maxProgress = this.data.maxProgress
      this.valueProgress = this.data.valueProgress
      this.estimatedTime = this.toHHMMSS(this.data.estimatedTime)

      this.isOver = this.isSearchEnd()
    },
    methods: {
      isSearchEnd(){
        return this.valueProgress === this.maxProgress
      },
      stopCurrentSearch() {
        this.isOver = true

        EventBus.$emit('updateSearchStatus', false)
        stopSearch((response) => {
          if (!response) {
            this.notificationError('We failed to stop the search')
          }
        })
      },
      close(){
        this.$emit('close-notification-search-progress', false)
      },
      toHHMMSS(time) {
        let sec_num = parseInt(time, 10);
        let hours = Math.floor(sec_num / 3600);
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        let seconds = sec_num - (hours * 3600) - (minutes * 60);

        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }

        return hours + ':' + minutes + ':' + seconds;
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .notification-search-progress {

    .vs-notification {
      width: 26vw;
      max-width: none;

      .search-progress {
        align-items: center;
        margin-bottom: 0px;

        .vs-button {
          margin: 0px;
        }
      }
    }
  }
  </style>
