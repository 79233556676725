let extensionId = "pcjkfkacolphdlodaghfcgdiofplljcj";


async function reloadExtension(){
    await sendMessageToExtension({type :"reloadExtension"})
}

async function notLoggedInAlert(){
    console.log("401 or 403")
    await sendMessageToExtension({type :"notLoggedIn"})
}
async function isASearchRunning(callback){
    return(await sendMessageToExtension({type :"isASearchRunning"}, callback));
}

async function relaunchSearch(searchId){
    return searchId;
    //this function should not be necessary with socketio refactor
   // return(await sendMessageToExtension({type :"relaunchSearch", searchId : searchId}));
}

async function stopSearch(callback) {
    const message = {
        origin: "skfApp",
        type: "updateSearchStatus",
        status: false
    };

   return (await sendMessageToExtension(message, callback))
}

/**
 * Start onboarding proccess for new user
 *
 * @param callback
 * @returns {Promise<void>}
 */
async function onboardingProcess(callback){
    return(await sendMessageToExtension({type :"onboardingProcess"}, callback));

}

async function sendMessageToExtension(msg, callback){
    global.chrome.runtime.sendMessage(extensionId, msg, callback);
}



module.exports = {
    reloadExtension,
    notLoggedInAlert,
    isASearchRunning,
    relaunchSearch,
    stopSearch,
    onboardingProcess
};
