import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fr from '@/locales/fr.json'
import en from '@/locales/en.json'
import {getNavigatorLanguage} from "../helpers/helper";

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: getNavigatorLanguage(true),
    fallbackLocale: 'en',
    messages: {fr, en},
})
