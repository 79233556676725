<template>
  <vs-select class="language-switcher" v-model="selected" state="dark" color="dark">
    <vs-option v-for="(locale, index) in locales" :key="index" :label="locale.toUpperCase()" :value="locale">
      {{ locale.toUpperCase() }}
    </vs-option>
  </vs-select>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  data() {
    return {
      locales: ['fr', 'en'],
      selected: '',
    }
  },
  created() {
    this.selected = this.locales.includes(this.$i18n.locale) ? this.$i18n.locale : this.$i18n.fallbackLocale
  },
  watch: {
    selected(locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style lang="scss">

.language-switcher.vs-select-content {
  width: 70px;
}

</style>