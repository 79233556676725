
<template>
    <div class="hidden">
        <vs-sidebar
                fixed
                hover-expand
                open
                reduce
                v-model="active"
                style="margin-top: 51px;padding-bottom: 51px;"
        >

          <vs-sidebar-item id="home" to="/">
            <template #icon>
              <i class='bx bx-home'/>
            </template>
            {{$t('main.home')}}
          </vs-sidebar-item>


          <vs-sidebar-item
              v-for="project in projects"
              :key="project._id"
              :id="project._id"
              :to="{name: 'Project', params: {idProject: project._id}}"
          >
            <template #icon>
              <vs-avatar size="40">
                  {{project.emoji ? project.emoji : emoji[Math.floor(Math.random()*emoji.length)]}}
              </vs-avatar>
            </template>
            {{ project.name }}
          </vs-sidebar-item>

          <template #footer>
                <vs-row justify="space-between">
                    <vs-tooltip right>
                        <vs-avatar badge-color="danger" badge-position="top-right" style="cursor:pointer;" @click="reload()">
                            <i class='bx bx-refresh' ></i>
                        </vs-avatar>
                        <template #tooltip>
                            Refresh extension. Use it only if the app is freezing.
                        </template>
                    </vs-tooltip>
                    <vs-avatar history primary>
                        <template #text>
                            {{name}}
                        </template>
                    </vs-avatar>
                </vs-row>

            </template>
        </vs-sidebar>
    </div>
</template>
<script>
    import {reloadExtension} from "@/services/extensionMessaging";
    import {getAllUserProject} from "@/utils/project/getAllForUser";
    import EventBus from '@/services/event-bus';

    export default {
        data: () => ({
            projects: [],
            active: 'home',
            name: '',
            emoji : ['🐣', '🦀' , '🦄','🧞', '👻', '👾', '🦅', '🤖', '🧠' , '🚀', '🦊', '🐰', '🐭', '🐱', '🐶', '🐯', '🦁', '🐮', '🐷', '🐸', '🐵', '🐼', '🙊', '🐌', '🦋', '🕷️', '🦗', '🐝', '🐡', '🐢', '🐍', '🦎', '🦍', '🦧', '🦚', '🦜', '🦢', '🦩', '🦝', '🍀', '🍄', '🐚', '🌻', '🌸', '🌼', '🌺', '🔥', '🌈', '❄️', '☃️', '🍟', '🍕', '🥪', '🥙', '🧆', '🌮', '🧀', '🥑', '🍓', '🍇', '🍉', '🥝', '🍍', '🎂', '🍮', '🧁', '🍭', '🍧', '🍪', '🍩', '🍿', '🍻', '🍺', '🍷', '🍾', '🥃', '🧂', '⚽', '🏀','🏈', '⚾', '🎾', '🏐', '🏉', '🥏', '🎱', '🪀', '🏓', '🎭', '🎨', '🎬', '🎧','🎯', '🎲', '♟️']
        }),
        created() {
            this.getUserProject()
        },
        methods: {
            reload() {
                reloadExtension()
            },
            async getUserProject() {
                await getAllUserProject()
                    .then(res => {
                        this.projects = res.data.projects ? res.data.projects.reverse() : []
                    })
            },
            handleCreateProject(project) {
                this.projects.unshift(project)
            },
            handleUpdateProject() {
                getAllUserProject()
                    .then(res => {
                        this.projects = res.data.projects ? res.data.projects.reverse() : []
                    })
            },
        },
        mounted() {
            EventBus.$on('createProject', this.handleCreateProject);
            EventBus.$on('updateProject', this.handleUpdateProject);
            this.name = window.localStorage.getItem('name');
        },
    }
</script>


