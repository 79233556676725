import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import Vuesax from 'vuesax'
import Buefy from 'buefy'
import { i18n } from '@/plugins/i18n'

import 'vuesax/dist/vuesax.css'
import '@/assets/styles/index.scss'
import "@/assets/styles/_buefy.scss";

import NotificationMixin from "@/mixins/NotificationMixin";

Vue.use(VueCookies)

Vue.use(Buefy);

//Vuesax styles
Vue.use(Vuesax, {
  colors: {
    primary:'#48a9a6',
    secondary: '#007090',
    third: '#18206f',
    clear: '#eee5e9',
    pink: '#ef476f',
    success:'#18d183',
    danger:'#ab2346',
    warning:'#ddd052',
    dark:'rgb(36, 33, 69)'
  }
});


Vue.mixin(NotificationMixin)

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
